import BlockContent from '@sanity/block-content-to-react';
import * as React from 'react';

import type { ISanityCopy } from '../../fragments/sanity-copy-parts';
import type { ISanityCopyOnly } from '../../fragments/sanity-copy-only-parts';
import type { TColourScheme } from '../../types';
import { classNames } from '../../utils/classnames';
import {
  BG_COLOUR_MAP,
  PROSE_COLOUR_MAP,
  TEXT_COLOUR_MAP,
} from '../../utils/object-dictionaries';
import { ButtonLink } from '../button-link';

interface CopyOnlyProps {
  copyOnly: ISanityCopyOnly;
}

function CopyOnly({ copyOnly }: CopyOnlyProps): JSX.Element {
  console.log(copyOnly);
  return (
    <div className={classNames(BG_COLOUR_MAP[copyOnly.colourScheme])}>
      <div className="w-full px-4 py-12 mx-auto max-w-prose lg:py-24 lg:max-w-screen-2xl sm:px-6 lg:px-8">
        <Copy
          key={module.id}
          module={copyOnly.body}
          colourScheme={copyOnly.colourScheme}
        />
      </div>
    </div>
  );
}

interface CopyProps {
  module: ISanityCopy;
  colourScheme: TColourScheme;
}

function Copy({ module, colourScheme }: CopyProps): JSX.Element {
  return (
    <div className="pb-5 lg:py-12">
      <BlockContent
        renderContainerOnSingleChild
        blocks={module._rawBody}
        className={classNames(
          'prose prose-green max-w-none',
          TEXT_COLOUR_MAP[colourScheme],
          PROSE_COLOUR_MAP[colourScheme]
        )}
      />
      {module.ctas
        ? module.ctas.map((cta) => (
            <p key={cta.id} className="mt-8">
              <ButtonLink link={cta} colourScheme={colourScheme} />
            </p>
          ))
        : null}
    </div>
  );
}

export { CopyOnly };
export type { ISanityCopyOnly };
