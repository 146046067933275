import * as React from 'react';
import { BiPhone, BiBell, BiHomeAlt } from 'react-icons/bi';
import { MdMailOutline } from 'react-icons/md';
import { FiClock, FiHome } from 'react-icons/fi';

import { useSanityGeneralSettings } from '../hooks/use-sanity-general-settings';
import { TColourScheme } from '../types';
import { classNames } from '../utils/classnames';
import { getIcon } from '../utils/get-icon';
import { TEXT_COLOUR_MAP } from '../utils/object-dictionaries';

interface ContactInfoProps {
  title?: string;
  colourScheme?: TColourScheme;
}
<FiClock className="w-5 h-5" />;
function ContactInfo({
  colourScheme = 'dark',
  title,
}: ContactInfoProps): JSX.Element {
  const settings = useSanityGeneralSettings();
  return (
    <div className={TEXT_COLOUR_MAP[colourScheme]}>
      {title ? <h2 className="text-4xl font-bold">{title}</h2> : null}
      <dl className={classNames('space-y-4 text-lg', title && 'mt-6')}>
        <div className="flex items-center space-x-2">
          <dt className="inline ">
            <BiPhone className="w-5 h-5" />
          </dt>
          <dd className="inline">
            <a
              href={`tel:${settings.phoneNumber}`}
              className="font-bold hover:underline focus:underline"
            >
              {settings.phoneNumber}
            </a>
          </dd>
        </div>
        <div className="flex items-center space-x-2">
          <dt className="inline font-bold">
            <MdMailOutline className="w-5 h-5" />{' '}
          </dt>
          <dd className="inline">
            <a
              href={`mailto:${settings.email}`}
              className="font-bold break-all hover:underline focus:underline"
            >
              {settings.email}
            </a>
          </dd>
        </div>
        <div className="flex items-center space-x-2">
          <dt className="inline font-bold">
            <BiHomeAlt className="w-5 h-5" />
          </dt>
          <dd className="inline">
            <a
              href={settings.address.googleMaps.link}
              className="font-bold hover:underline focus:underline"
            >
              {settings.address.streetAddress},
              <span className="inline-block">{settings.address.suburb}</span>
            </a>
          </dd>
        </div>
        <div className="flex items-center space-x-2">
          <dt className="inline font-bold">
            <FiClock className="w-5 h-5" />{' '}
          </dt>
          <dd className="inline">
            <ul className="inline">
              {settings.hours.map(({ days, hours, id }) => (
                <li key={id} className="inline font-bold">
                  {days}&nbsp;– <span className="inline-block">{hours}</span>
                </li>
              ))}
            </ul>
          </dd>
        </div>
        <div className="inline-flex items-center py-4">
          {/* <dt className="inline-flex font-bold">Follow Us: </dt> */}
          <dd className="inline-flex space-x-2">
            {settings.socialLinks.map((socialLink) => (
              <a
                key={socialLink.id}
                href={socialLink.url}
                className="inline-flex items-center font-bold hover:underline focus:underline"
              >
                <span className="sr-only">{socialLink.icon}</span>
                {getIcon({
                  socialNetwork: socialLink.icon,
                  className: 'w-6 h-6',
                })}
              </a>
            ))}
          </dd>
        </div>
      </dl>
    </div>
  );
}

export { ContactInfo };
